import React, {useState, useEffect, useRef} from 'react'
import propTypes from 'prop-types'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import {useTheme} from '@material-ui/core/styles'
import styled from 'styled-components'
import inViewport from '../../../util/inViewport'
import {debounce} from 'lodash';


const matchesBreakpoint = (bp, theme) => {
  
  // const img = useMediaQuery(theme.breakpoints.up(bp));

  if (process.browser) {
    const trimBp = theme.breakpoints.up(bp).replace('@media', '').trim()
    const matchMedia = window.matchMedia(trimBp);
    return matchMedia.matches
  } else {
    return true
  }

}

const setData = (i, key, type) => {
  const jpg = i[key].jpg
  const png = i[key].png
  const webp = i[key].webp ? i[key].webp : null
  const width = i[key].width ? i[key].width : null
  const height = i[key].height ? i[key].height : null

  return {
    src: type === 'jpg' ? jpg : png,
    webp,
    width,
    height
  }
}

const getResponsiveSrc = (i, type, theme) => {
  let data = {}

  if (!i) {
    return {}
  }
  
  // First check if there's a 'default' image key
  if (i.default) {
   data = setData(i, 'default', type)
  }
  
  for (var j = 0; j < theme.breakpoints.keys.length; j++) {
    var key = theme.breakpoints.keys[j]
    if (i[key] && matchesBreakpoint(key, theme)) {
      // console.log('daaa2', i,key, type)
      data = setData(i, key, type)
    }
  }

  return data
}

const fluid = 'display: block; width: 100%; height: auto;'

const Picture = styled.picture`
  ${props => props.fluid && `${fluid} img {${fluid}}`};
  filter: ${props => props.blur === 1 ? `blur(5px)` : ` blur(0)`};
  transition: all 300ms linear;
`

const ResponsiveImage = ({
  fluid,
  alt,
  sizes,
  type,
  placeholderSrc,
  settings,
  lazyLoad,
  blur,
  allSizes,
  original,
  backupSize,
  _console,
  showNoImage,
  placeholderImage,
  loading,
  visible
}) => {

  const imgRef = useRef(null);
  const [webp, showWebp] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [browserLoaded, setBrowserLoaded] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(lazyLoad ? true : false) // useState(placeholderSrc ? true : false);

  const respSettings = {
    threshold: 400, // load img when 500px or less away from viewport
    ...settings
  }

  const listener = (e) => {
    const show = inViewport(imgRef.current, respSettings.threshold);
    const isVisible = !imgRef.current || (imgRef.current && !imgRef.current.checkVisibility) ? true : (imgRef.current && imgRef.current.checkVisibility) ? imgRef.current.checkVisibility() : false;
    if (show === true && isVisible) {
      setShowPlaceholder(false)
    }
  };

  const init = () => {
    setBrowserLoaded(true)
  }

  useEffect(() => {
    init();
    listener();
    const delay = 200;
    window.addEventListener('scroll', debounce(listener, delay));
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [])

  useEffect(() => {
    // if image is hidden initialy e.g. menu or modal
    if (visible) {
      console.log('visible', visible);
      listener();
    }
  }, [visible])

  useEffect(() => {
    setIsLoaded(false)
  }, [showPlaceholder])

  const handleLoad = () => {
    
    setIsLoaded(true)
  }

  const hideWebp = () => {
    if (img.webp) {
      showWebp(false);
    }
  }

  const firstImg = (sizes && Object.keys(sizes)[0]) ? sizes[Object.keys(sizes)[0]] : {}

  const blankImg = {
    width: firstImg.width,
    height: firstImg.height,
    alt,
    src: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
  }

  const theme = useTheme();

  const respSrc = getResponsiveSrc(sizes, type, theme);
  const placeHolder = getResponsiveSrc({default: placeholderSrc}, type, theme);
  const backupImgItem = allSizes.find(item => item.name === backupSize)
  const backupImg = backupImgItem ? {
    ...backupImgItem,
    src: type === 'jpg' ? backupImgItem.jpg : backupImgItem.png,
  } : null

  const initialImg = browserLoaded === false ? blankImg : ((showPlaceholder && lazyLoad) ? (placeholderSrc ? placeHolder : blankImg) : respSrc) || backupImg
  
  const img = initialImg.src ? initialImg : backupImg || {}

  return (
    <Picture fluid={fluid} ref={imgRef} blur={blur && !isLoaded ? 1 : 0}>
      {webp !== false && img.webp &&
        <source type="image/webp" srcSet={img.webp} />
      }
      <source srcSet={(showNoImage && placeholderImage && !webp) ? placeholderImage : img.src} />
      <img loading={loading} alt={alt || ''} src={(showNoImage && placeholderImage && !webp) ? placeholderImage : img.src} width={img.width} height={img.height} onError={() => hideWebp()} onLoad={() => (img !== placeHolder || !sizes) ? handleLoad() : null}/>
    </Picture>
  )
}
  
ResponsiveImage.propTypes = {
  fluid: propTypes.bool,
  alt: propTypes.string.isRequired,
  sizes: propTypes.object.isRequired,
  type: propTypes.oneOf(['png', 'jpg']),
  settings: propTypes.object,
  placeholderSrc: propTypes.any,
  lazyLoad: propTypes.bool,
  blur: propTypes.bool,
  backupSize: propTypes.string,
  allSizes: propTypes.array,
  showNoImage: propTypes.bool,
  placeholderImage: propTypes.string,
  visible: propTypes.bool
}

ResponsiveImage.defaultProps = {
  fluid: true,
  alt: "",
  type: 'jpg',
  settings: {},
  placeholderSrc: null,
  lazyLoad: true,
  blur: false,
  backupSize: 'medium',
  allSizes: [],
  showNoImage: false,
  placeholderImage: "",
  loading: 'lazy',
  visible: true
}

  
export default ResponsiveImage